import './default'

const $navLink = $('.hero__nav__item-link')
$navLink.mouseover(function () {
    $navLink.removeClass('hero__nav__item-link--active-no-style')
    $navLink.removeClass('hero__nav__item-link--active-style')
    $(this).addClass('hero__nav__item-link--active-style')
  }
)

function followingElement() {
  const $heroNav = $('#heroNav')
  $heroNav.append('<li id="followingElement"></li>')
  const $followingElement = $('#followingElement')
  $followingElement.addClass('hero__nav__item-link-following-elem--hidden')
  $followingElement.css('top', $('.hero__nav__item-link--active-no-style').position().top + 5)
  $followingElement.css('left', $('.hero__nav__item-link--active-no-style').position().left + $('.hero__nav__item-link--active-no-style').width() + 45)

  $navLink.mouseover(function () {
    $followingElement.removeClass('hero__nav__item-link-following-elem--hidden')
    $followingElement.addClass('hero__nav__item-link-following-elem--visible')
    $followingElement.stop().animate({
      top: $('.hero__nav__item-link--active-style').position().top + 5,
      left: $('.hero__nav__item-link--active-style').position().left + ($('.hero__nav__item-link--active-style').width() + 45)
    }, 50);
  })
}

followingElement()

///bg shapes movement
const posProps = [
  {
    'top': '-260px',
    'left': '-40px'
  },
  {
    'top': '-230px',
    'left': '-10px'
  },
  {
    'top': '-200px',
    'left': '20px'
  },
  {
    'top': '-170px',
    'left': '50px'
  },
  {
    'top': '-140px',
    'left': '80px'
  },
  {
    'top': '-110px',
    'left': '110px'
  },
  {
    'top': '-90px',
    'left': '140px'
  }
]

$('.hero__nav__item-link--desk').each(function (i) {
  $(this).mouseover(function () {
    let top = posProps[i].top
    let left = posProps[i].left
    $('.hero-shapes-bg-elem-wrapper').animate({top: `${top}`, left: `${left}`}, 850)
  })
})

///projects add border to 'projects__item-hidden' on hover
$('.projects__item-hidden-button').hover(function () {
  $(this).parent().css({border: '5px solid #F7BE0D'})
}, function () {
  $(this).parent().css({border: '0px solid #F7BE0D'})
})

///comments slider
$('.js-comments-slider').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: false,
  infinite: false,
  // adaptiveHeight: true
});

$('.last__comments__item__read-more').on('click', function (e) {
  $('.last__comments__item-text').removeClass('last__comments__item-text--minimized')
  $('.last__comments__item__read-less').show()
  $('.last__comments__item__read-more').hide()
  $('.js-comments-slider').slick('slickSetOption', {
    adaptiveHeight: true
  }, true);
})

$('.last__comments__item__read-less').on('click', function () {
  $('.last__comments__item-text').addClass('last__comments__item-text--minimized')
  $('.last__comments__item__read-less').hide()
  $('.last__comments__item__read-more').show()
  $('.js-comments-slider').slick('slickSetOption', {
    adaptiveHeight: true
  }, true);
})

// listen for scroll event and call animate function
document.addEventListener('scroll', animate);
document.addEventListener('DOMContentLoaded', animate);

// check if element is in view
function inView(element, val, valMob) {
  return element.getBoundingClientRect().top + (window.innerWidth > 576 ? val : valMob) < window.innerHeight
}

// animate element when it is in view
function animate() {
  ///section service
  if (inView(document.getElementById('circle'), 550, 300)) {
    $('.services__block-left__item-title--sphere').removeClass('services__block-left__item-title--active-sphere');
    $('.services__block-left__item-title--cube').removeClass('services__block-left__item-title--active');

    $('.services__block-right__circle-upper').addClass('services__block-right__circle-upper--animated');
    $('.services__block-right__circle-lower').addClass('services__block-right__circle-lower--animated');
    $('.services__block-left__item-title--circle').addClass('services__block-left__item-title--active');

    $('.services__block-right__cube-big').removeClass('services__block-right__cube-big--animated');
    $('.services__block-right__cube-small').removeClass('services__block-right__cube-small--animated');
    $('.services__block-right__sphere').removeClass('services__block-right__sphere-animated');
  } else {
    $('.services__block-right__circle-upper').removeClass('services__block-right__circle-upper--animated');
    $('.services__block-right__circle-lower').removeClass('services__block-right__circle-lower--animated');
  }
  if (inView(document.getElementById('sphere'), 400, 400)) {
    $('.services__block-left__item-title--circle').removeClass('services__block-left__item-title--active');
    $('.services__block-left__item-title--cube').removeClass('services__block-left__item-title--active');

    $('.services__block-right__sphere').addClass('services__block-right__sphere-animated');
    $('.services__block-left__item-title--sphere').addClass('services__block-left__item-title--active-sphere');

    $('.services__block-right__circle-upper').removeClass('services__block-right__circle-upper--animated');
    $('.services__block-right__circle-lower').removeClass('services__block-right__circle-lower--animated');
    $('.services__block-right__cube-big').removeClass('services__block-right__cube-big--animated');
    $('.services__block-right__cube-small').removeClass('services__block-right__cube-small--animated');
  }
  if (inView(document.getElementById('cube'), 400, 400)) {
    $('.services__block-left__item-title--circle').removeClass('services__block-left__item-title--active');
    $('.services__block-left__item-title--sphere').removeClass('services__block-left__item-title--active-sphere');

    $('.services__block-right__cube-big').addClass('services__block-right__cube-big--animated');
    $('.services__block-right__cube-small').addClass('services__block-right__cube-small--animated');
    $('.services__block-left__item-title--cube').addClass('services__block-left__item-title--active');

    $('.services__block-right__circle-upper').removeClass('services__block-right__circle-upper--animated');
    $('.services__block-right__circle-lower').removeClass('services__block-right__circle-lower--animated');
    $('.services__block-right__sphere').removeClass('services__block-right__sphere-animated');
  }

  ///section clients

  ///animate yellow line
  if (inView(document.getElementById('brands__line-1'), 200, 200)) {
    $('.brands__line-1').addClass('brands__line-1--active');
  }
  if (inView(document.getElementById('brands__line-2'), 200, 200)) {
    $('.brands__line-2').addClass('brands__line-2--active');
    $('.brands__logo-wrapper--1').addClass('brands__logo-wrapper--active');
  }
  if (inView(document.getElementById('brands__line-3'), 200, 200)) {
    $('.brands__line-3').addClass('brands__line-3--active');
    $('.brands__logo-wrapper--2').addClass('brands__logo-wrapper--active');
  }
  if (inView(document.getElementById('brands__line-4'), 200, 200)) {
    $('.brands__line-4').addClass('brands__line-4--active');
    $('.brands__logo-wrapper--3').addClass('brands__logo-wrapper--active');
  }

  ///years active class and animate arrow
  if (inView(document.getElementById('brands__year-1'), 100, 100)) {
    $('#brands__year-1').addClass('brands__year-active');
    $('#brands__year-2').removeClass('brands__year-active');
    $('#brands__year-3').removeClass('brands__year-active');
    $('#followingElement2').stop().animate({
      top: $('#brands__year-1').position().top,
    }, 500);
  }
  if (inView(document.getElementById('brands__year-2'), 100, 100)) {
    $('#brands__year-2').addClass('brands__year-active');
    $('#brands__year-1').removeClass('brands__year-active');
    $('#brands__year-3').removeClass('brands__year-active');
    $('#followingElement2').stop().animate({
      top: $('#brands__year-2').position().top,
    }, 500);
  }
  if (inView(document.getElementById('brands__year-3'), 100, 100)) {
    $('#brands__year-3').addClass('brands__year-active');
    $('#brands__year-1').removeClass('brands__year-active');
    $('#brands__year-2').removeClass('brands__year-active');

    $('#followingElement2').stop().animate({
      top: $('#brands__year-3').position().top,
    }, 500);
  }
}

function followingElement2() {
  const $followingElement = $('#followingElement2')
  $followingElement.css('top', $('#brands__year-1').position().top)
  $followingElement.css('left', $('#brands__year-1').position().left + $('#brands__year-1').width() - 60)
}

document.addEventListener('DOMContentLoaded', followingElement2);
document.addEventListener('scroll', followingElement2);